import classNames from 'classnames'
import styles from '../styles/header.module.css'
import { useRef, useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import NavMenu from './menu'

const NavContent = (props) => {
    const [isHover, setIsHover] = useState(false)
    const variants = {
        hidden: {
            height: 0,
            transition: { duration: 0 }
        },
        visible: {
            height: 5,
            transition: { duration: 0.25 }
        }
    }
    
    return (
        <a href='https://google.com/teapot'>
            <div className={styles.navContent} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                <span>{props.title}</span>
                <motion.div
                    className={styles.navEffect} variants={variants}
                    initial='hidden' animate={isHover ? 'visible' : 'hidden'}
                />
            </div>
        </a>
    )
}

export default function Header() {
    const handRef = useRef(null)
    const [isHandHover, setIsHandHover] = useState(false)
    const [isNavHover, setIsNavHover] = useState(false)

    useEffect(() => {
        if (isHandHover) {
            handRef.current.src = 'https://www.railpark.co.kr/images/ico_handOn.png'
        } else {
            handRef.current.src = 'https://www.railpark.co.kr/images/ico_hand.png'
        }
    }, [isHandHover])

    return <>
        <div className={styles.header} style={{ backgroundColor: isNavHover ? 'white' : '' }}>
            <div className={styles.logo}>
                <img src='https://www.railpark.co.kr/images/h1Logo.png' />
            </div>
            <nav className={styles.nav} onMouseEnter={() => setIsNavHover(true)} onMouseLeave={() => setIsNavHover(false)}>
                <NavContent title='강촌레일파크' />
                <NavContent title='레일바이크' />
                <NavContent title='경강 PET BIKE' />
                <NavContent title='간편예약 및 확인' />
                <NavContent title='주변관광지' />
                <NavContent title='커뮤니티' />
            </nav>
            <div className={styles.right}>
                <div className={styles.img}>
                    <img src='https://www.railpark.co.kr/images/ico_rock.png' />
                </div>
                <div className={styles.register}>
                    <img src='https://www.railpark.co.kr/images/ico_user.png' />
                </div>
                <div
                    className={classNames(styles.button, styles.ticket)}
                    onMouseEnter={() => setIsHandHover(true)}
                    onMouseLeave={() => setIsHandHover(false)}
                >
                    예약조회
                    <img src='' ref={handRef} />
                </div>
                <div className={classNames(styles.button, styles.lang)}>
                    KOREAN<br />
                    <img src='https://www.railpark.co.kr/images/ico_topArrow.png' />
                </div>
            </div>
        </div>
        <NavMenu state={isNavHover} dispatch={setIsNavHover} />
    </>
}
