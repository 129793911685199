import { useState, useEffect, useRef } from 'react'
import styles from './styles/img.module.css'

const backgrounds = [
    'https://www.railpark.co.kr/uploaded/banner/d1700830609bed25a12c8259d7f5bb75.jpg',
    'https://www.railpark.co.kr/uploaded/banner/b25fb63303f938352b344bddd12ea5c2.jpg',
    'https://www.railpark.co.kr/uploaded/banner/c5d6d611a0ea1fe5683e9ec214beedad.jpg',
    'https://www.railpark.co.kr/uploaded/banner/be7696be79635b5c8b08207cf950d1cc.jpg',
]
const titles = [
    'https://www.railpark.co.kr/uploaded/banner/777cdb73dad066ea2978c6f6ab939d7b.png',
    'https://www.railpark.co.kr/uploaded/banner/74ffdf22cb1a4105a593d0837a9bf61f.png',
    'https://www.railpark.co.kr/uploaded/banner/47fe16c86e186151acac73a9658bcca0.png',
    'https://www.railpark.co.kr/uploaded/banner/e86fc55098259106a49879542437810e.png'
]

export default function Images() {
    const [currentImage, setCurrentImage] = useState(0)
    const [isAutoPlay, setIsAutoPlay] = useState(true)
    const [skipable, setSkipable] = useState(true)
    const backgroundRef = useRef(null)
    const autoChange = () => isAutoPlay && setCurrentImage(prev => prev === 3 ? 0 : prev += 1)

    useEffect(() => {
        const interval = setInterval(() => autoChange(), 3000)
        return () => clearInterval(interval)
    }, [])
    useEffect(() => {
        setSkipable(false)
        setTimeout(() => setSkipable(true), 1000)
    }, [currentImage])

    return (
        <div className={styles.slide}>
            <div className={styles.title}>
                <img src={titles[0]} style={{ opacity: currentImage === 0 ? 1 : 0 }} />
                <img src={titles[1]} style={{ opacity: currentImage === 1 ? 1 : 0 }} />
                <img src={titles[2]} style={{ opacity: currentImage === 2 ? 1 : 0 }} />
                <img src={titles[3]} style={{ opacity: currentImage === 3 ? 1 : 0 }} />
            </div>
            <div className={styles.control}>
                <span className={currentImage === 0 ? styles.selected : ''} onClick={() => skipable && setCurrentImage(0)} />
                <span className={currentImage === 1 ? styles.selected : ''} onClick={() => skipable && setCurrentImage(1)} />
                <span className={currentImage === 2 ? styles.selected : ''} onClick={() => skipable && setCurrentImage(2)} />
                <span className={currentImage === 3 ? styles.selected : ''} onClick={() => skipable && setCurrentImage(3)} />
                <img
                    className={styles.stop} onClick={() => setIsAutoPlay(prev => !prev)}
                    src={isAutoPlay ? 'https://www.railpark.co.kr/images/mainSlideStop.png' : 'https://www.railpark.co.kr/images/mainSlideStop.png'}
                />
            </div>
            <button className={styles.arrow} style={{ left: 0, background: `url(https://www.railpark.co.kr/images/mainArrowLeft.png)` }} onClick={() => skipable && setCurrentImage(prev => prev === 0 ? 3 : prev -= 1)} />
            <button className={styles.arrow} style={{ right: 0, background: `url(https://www.railpark.co.kr/images/mainArrowRight.png)` }} onClick={() => skipable && setCurrentImage(prev => prev === 3 ? 0 : prev += 1)} />
            <div className={styles.background} ref={backgroundRef} style={{ backgroundImage: `url(${backgrounds[currentImage]})` }} />
        </div>
    )
}
