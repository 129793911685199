import BikeSection from './bikeSection'
import Header from './header/header'
import Images from './img'

export default function App() {
    return (
        <main>
            <Header />
            <Images />
            <BikeSection />
        </main>
    )
}
