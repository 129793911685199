import { motion } from 'framer-motion'
import { useState } from 'react'

import styles from './styles/bikeSection.module.css'

function Bike({ background, color, title, bottom, delay, isShow }) {
    const variants = {
        hidden: {
            opacity: 0,
            y: -230,
        },
        visible: {
            opacity: 1,
            y: -430,
        }
    }

    return (
        <motion.div
            className={styles.section} variants={variants}
            style={{ backgroundImage: `url('${background}')` }}
            initial='hidden' animate={isShow ? 'visible' : 'hidden'} transition={{ ease: 'easeOut', duration: 1, delay }}
        >
            <div className={styles.title} style={{ color }}>
                <img src='https://www.railpark.co.kr/images/mainBtnLogo.png' />
                <p>{title}</p>
                <p>레일바이크</p>
            </div>
            <div className={styles.bottom} style={{ backgroundImage: `url('${bottom}')` }}>
                <div className={styles.content}>
                    <span className={styles.reserve}>
                        <img src='https://www.railpark.co.kr/images/ico_cal.png' />
                        예약
                    </span>
                    <span>
                        <img src='https://www.railpark.co.kr/images/ico_search.png' />
                        보러가기
                    </span>
                </div>
            </div>
        </motion.div>
    )
}

export default function BikeSection() {
    const [isShow, setIsShow] = useState(false)

    return (
        <motion.div className={styles.list} onViewportEnter={() => setIsShow(true)} onViewportLeave={() => setIsShow(false)}>
            {
                [{
                    background: 'https://www.railpark.co.kr/images/mainBtn01.png',
                    color: '#ed1940',
                    title: '김유정',
                    bottom: 'https://www.railpark.co.kr/images/mainBtn01Bg.png',
                }, {
                    background: 'https://www.railpark.co.kr/images/mainBtn02.png',
                    color: '#5b9f34',
                    title: '경강',
                    bottom: 'https://www.railpark.co.kr/images/mainBtn02Bg.png',
                }, {
                    background: 'https://www.railpark.co.kr/images/mainBtn03.png',
                    color: '#fb9a02',
                    title: '가평',
                    bottom: 'https://www.railpark.co.kr/images/mainBtn03Bg.png',
                }].map((data, i) => <Bike {...data} delay={i*0.15} isShow={isShow} />)
            }
        </motion.div>
    )    
}
