import { motion } from 'framer-motion'
import styles from '../styles/header.module.css'

export default function NavMenu({ dispatch, state }) {
    const variants = {
        hidden: {
            height: 0,
        },
        visible: {
            height: 240,
        }
    }

    return (
        <motion.div
            className={styles.menu} variants={variants}
            initial='hidden' animate={state ? 'visible' : 'hidden'}
            transition={{ ease: 'easeOut', duration: 0.23 }}
            onMouseEnter={() => dispatch(true)} onMouseLeave={() => dispatch(false)}
        >
            <div className={styles.list} style={{ width: 158, marginLeft: 500, paddingTop: state ? 40 : 0, borderLeft: `1px solid #eeeeee` }}>
                <a href='https://google.com/teapot'>인사말</a>
                <a href='https://google.com/teapot'>오시는 길</a>
            </div>
            <div className={styles.list} style={{ width: 142, paddingTop: state ? 40 : 0 }}>
                <a href='https://google.com/teapot'>김유정 레일바이크</a>
                <a href='https://google.com/teapot'>경강 레일바이크</a>
                <a href='https://google.com/teapot'>- PET BIKE</a>
                <a href='https://google.com/teapot'>가평 레일바이크</a>
            </div>
            <div className={styles.list} style={{ width: 173, paddingTop: state ? 40 : 0 }}>
                <a href='https://google.com/teapot'>PET BIKE 소개</a>
                <a href='https://google.com/teapot'>예약 및 이용안내</a>
            </div>
            <div className={styles.list} style={{ width: 183, paddingTop: state ? 40 : 0 }}>
                <a href='https://google.com/teapot'>간편예약 하기</a>
                <a href='https://google.com/teapot'>예약확인 / 취소</a>
                <a href='https://google.com/teapot'>단체예약 문의</a>
            </div>
            <div className={styles.list} style={{ width: 142, paddingTop: state ? 40 : 0 }}>
                <a href='https://google.com/teapot'>레인보우벨리</a>
                <a href='https://google.com/teapot'>강존낭만여행</a>
            </div>
            <div className={styles.list} style={{ width: 125, paddingTop: state ? 40 : 0 }}>
                <a href='https://google.com/teapot'>공지사항</a>
                <a href='https://google.com/teapot'>Q&A</a>
                <a href='https://google.com/teapot'>자주하는 질문</a>
                <a href='https://google.com/teapot'>제휴문의</a>
            </div>
        </motion.div>
    )
}
